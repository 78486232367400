import { stringify } from 'querystring';
import { apiInstance } from 'utils/requestHelper';
import { errorHandler } from 'utils/errorHandler';
import { getToken } from 'smu-utils/storage';
import { addParams, formData, times, vo } from './../../api';

export function uploadImage({ image }) {
  const formData = new FormData();
  formData.append('token', getToken());
  formData.append('file', image);
  formData.append('fileName', image.name);

  return apiInstance({
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    timeout: 0,
    url: addParams('/v2/image/insert/image', { ...vo, ...times() }),
  })
    .then(({ data }) => (data))
    .catch(errorHandler);
}

export function changeProfilePicture({ imageCode, id }) {
  return apiInstance({
    url: addParams(`/v2/stellar/users/${id}/image/${imageCode}`, { ...vo, ...times() }),
    ...formData,
    data: stringify({
      ...vo,
      ...times(),
    }),
  })
    .then(({ data }) => (data))
    .catch(errorHandler);
}

export function registerOsFeedPreference(params) {
  const { osfeed, userUid } = params;
  const baseUrl = `/v2/users/${userUid}/property`;
  return apiInstance({
    url: addParams(baseUrl, {
      ...vo,
      ...times(),
    }),
    data: { osfeed },
    headers: { 'Content-Type': 'application/json' },
    method: 'post',
  })
    .then(({ data }) => {
      if (data.status !== 'OK') {
        return { error: true };
      }
      return { error: false };
    }).catch(errorHandler);
}
