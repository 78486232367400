// @packages
import React, { useEffect } from 'react';
import parse from 'url-parse';

// @app
import AppLoader from 'containers/AppLoader';
import { getFullPathname, getPathnameByHash } from 'utils/url';
import { paths } from 'utils/routes';

function DefaultLayout({ router, ...rest }) {
  const { pathname } = router.location;
  const isHome = pathname === '/';

  useEffect(() => {
    if (isHome) {
      const parseUrl = parse(window.location.href);
      const pathnameHash = getPathnameByHash();

      if (pathnameHash) {
        router.replace(pathnameHash);
      } else {
        parseUrl.set('pathname', paths.home());
        const path = getFullPathname(parseUrl.href);
        router.replace(path);
      }
    }
  }, [isHome]);

  return <AppLoader router={router} {...rest} />;
}

export default DefaultLayout;
